import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { NEONPV_CONFIG } from 'config';  // config.js 파일의 경로를 올바르게 지정하십시오.
import {TextField} from '@mui/material';
import { ca } from 'date-fns/locale';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://castprocsms.kr/sign-in#/">
        castpro
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function RemoteCharge(props) {
  const { match } = props;
  const socketRef = React.useRef(null);
  const [statId, setStatId] = useState('');
  const [chargerId, setChargerId] = useState('');
  
  // 시간 데이터 생성
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const EdiDate = year + month + day + hours + minutes + seconds;

  useEffect(() => {
    // 웹소켓 연결
    socketRef.current = new WebSocket(`${NEONPV_CONFIG.WEBSOCKET_URL}/ocpp/PCSOCK`);

    // 메시지 수신 이벤트
    socketRef.current.onmessage = (event) => {
      // setMessages((prev) => [...prev, event.data]);
      console.log(event.data)
    };
    
    // 연결 종료 시 정리
    return () => {
      socketRef.current.close();
    };
  }, [])

  const handleStatIdChange = (event) => {
    setStatId(event.target.value);
  };

  const handleChargerIdChange = (event) => {
    setChargerId(event.target.value);
  };

  const RemoteStartTransaction = async () => {
    webSocketSend(JSON.stringify([2,"61458032496896","RemoteStartTransaction",{"statId":statId, "chargerId":chargerId}]))
  }
  
  const RemoteStopTransaction = async () => {
    webSocketSend(JSON.stringify([2,"61458032496896","RemoteStopTransaction",{"statId":statId, "chargerId":chargerId}]))
  }

  const webSocketSend = (message) => {
    if (socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(message);
    }
  }
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            원격 충전 시작/종료
          </Typography>

          {/* <form id="payForm" name='payForm' method="post"> */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    autoFocus
                    fullWidth
                    id="statId"
                    name="statId"
                    value={statId}
                    onChange={handleStatIdChange}
                    label="충전소 예시) 100116"
                    inputProps={{maxLength: 6}}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="chargerId"
                    name="chagerId"
                    value={chargerId}
                    onChange={handleChargerIdChange}
                    label="충전기ID 예시) 1 혹은 01"
                    inputProps={{maxLength: 2}}
                  />
                </Grid>
              </Grid>

              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 0 }}
                id="addCardBtn"
                onClick={RemoteStartTransaction}
              >
                충전 시작
              </Button>
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 0 }}
                id="addCardBtn"
                onClick={RemoteStopTransaction}
              >
                충전 종료
              </Button>
            {/* </form> */}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="https://castprocsms.kr/sign-in#/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
