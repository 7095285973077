

export let global = {
  webSocket_rul : 'ws://13.209.52.92:3003/',
  ws : null,
}


const ICCard = new RegExp('^900.021702170217$');
const CASTPROCard = new RegExp('^900.*((?!02170217).)*$');

/**
 * 카드번호로 부터 결제수단 판별
 * @param {*} cardno 
 * @returns 
 */
export const paymentMethodFromCardNo = (cardno, bid) => {
  if(bid == "ME"){
    return '환경부';
  }
  else if (bid == "CC"){
    return '신용카드';
  }
  else {
    return '회원';
  }
}