import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import numeral from 'numeral';

import {
  Search as SearchIcon,
} from "react-feather";

import { NEONPV_CONFIG } from "config.js";
import { connect, useSelector } from "react-redux";
import { USER_AUTH } from 'common/constants';
import {CSVLink} from "react-csv";


const COLUMN_WIDTH = {
  STAT_ID: '7%',
  STAT_NAME: '12%', //23
  CHARGER_NO: '6%', //29
  TRANS_AMOUNT: '5%', //35
  POWER_AMOUNT: '9%', //45
  CHARGING_AMOUNT: '9%', //55
  USER_CHARGING: '6%', //62
  ROAMINGUSER_CHARGING: '6%', //62
  LOAMING_CHARGING: '6%', //69
  ICCARD_CHARGING: '6%', //76
  MEMBER_KWH: '7%', //84
  ROAMINGMEMBER_KWH: '7%', //84
  LOAMING_KWH: '7%', //92
  CARD_KWH: '7%', //100
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) { return -1; }
  if (b[orderBy] > a[orderBy]) { return 1; }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const headCells = [
  {
    id: 'statId',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.STAT_ID,
    label: '충전소ID',
  },
  {
    id: 'statName',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.STAT_NAME,
    label: '충전소명',
  },
  {
    id: 'chargerId',
    numeric: true,
    disablePadding: true,
    width:COLUMN_WIDTH.CHARGER_NO,
    label: '충전기No',
  },
  {
    id: 'cnt',
    numeric: true,
    disablePadding: true,
    width:COLUMN_WIDTH.TRANS_AMOUNT,
    label: '이용건수',
  },
  {
    id: 'sum_wh',
    numeric: true,
    disablePadding: true,
    width:COLUMN_WIDTH.POWER_AMOUNT,
    label: '전력량집계[Wh]',
  },

  {
    id: 'sum_amount',
    numeric: true,
    disablePadding: true,
    width:COLUMN_WIDTH.CHARGING_AMOUNT,
    label: '충전금액집계[원]',
  },
  {
    id: 'sum_wh3',
    numeric: true,
    subTitle: true,
    disablePadding: true,
    width:COLUMN_WIDTH.MEMBER_KWH,
    label: '회원[Wh]',
  },
  {
    id: 'sum_wh5',
    numeric: true,
    subTitle: true,
    disablePadding: true,
    width:COLUMN_WIDTH.ROAMINGMEMBER_KWH,
    label: '로밍회원[Wh]',
  },
  {
    id: 'sum_wh4',
    numeric: true,
    subTitle: true,
    disablePadding: true,
    width:COLUMN_WIDTH.LOAMING_KWH,
    label: '환경부[Wh]',
  },
  {
    id: 'CARD_KWH',
    numeric: true,
    subTitle: true,
    disablePadding: true,
    width:COLUMN_WIDTH.CARD_KWH,
    label: '신용[Wh]',
  },
  {
    id: 'sum_amount3',
    numeric: true,
    subTitle: true,
    disablePadding: true,
    width:COLUMN_WIDTH.USER_CHARGING,
    label: '회원[원]',
  },
  {
    id: 'sum_amount5',
    numeric: true,
    subTitle: true,
    disablePadding: true,
    width:COLUMN_WIDTH.ROAMINGUSER_CHARGING,
    label: '로밍회원[원]',
  },
  {
    id: 'sum_amount4',
    numeric: true,
    subTitle: true,
    disablePadding: true,
    width:COLUMN_WIDTH.LOAMING_CHARGING,
    label: '환경부[원]',
  },
  {
    id: 'ICCARD_CHARGING',
    numeric: true,
    subTitle: true,
    disablePadding: true,
    width:COLUMN_WIDTH.ICCARD_CHARGING,
    label: '신용[원]',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'center'}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.subTitle ? (<Typography variant="body2">{headCell.label}</Typography>)
                : <Typography variant="body1">{headCell.label}</Typography>}

            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


const SalesChargerTable = (props) => {
  const { className, user, ...rest } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('OrderNo');

  const userAuth = useSelector((state) => state.user.user_auth);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const [memberName, setMemberName] = useState( userAuth !== USER_AUTH.ADMIN ? user.admin_name : '');
  const [chargeStartTime, setChargeStartTime] = useState(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
  const [chargeEndTime, setChargeEndTime] = useState(new Date((new Date()).valueOf()));
  const [queryStatName, setQueryStatName] = useState("");
  // const [queryChargerNo, setQueryChargerNo] = useState("");
  const [queryChargerPriceFrom, setQueryChargerPriceFrom] = useState('');
  const [queryChargerPriceTo, setQueryChargerPriceTo] = useState('');
  const [queryChargerQuantityFrom, setQueryChargerQuantityFrom] = useState('');
  const [queryChargerQuantityTo, setQueryChargerQuantityTo] = useState('');



  const searchData = (memberName, stime, etime, statName,
    ChargerPriceFrom, ChargerPriceTo,
    chargerQuantityFrom, chargerQuantityTo) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/sales/v2/salesChargerList?`;
    var startTime = moment(stime).format("YYMMDDHHmm");
    var endTime = moment(etime).format("YYMMDDHHmm");

    var params = "";

    // 콜센터 계정이라면
    if(user.admin_id == 'callcenter'){
      params += "memberId=castpro&";
    }
    //회원사 계정이라면
    else if (userAuth === USER_AUTH.MEMBER ) {
      params += "memberId=" + user.admin_id + "&";
    } 
    // 서브계정
    else if (userAuth === USER_AUTH.SUB_MEMBER){
      params += "memberId2=" + user.admin_id + "&";
    }
    else if (memberName) {
    //관리자 계정이라면
      params += "memberName=" + memberName + "&";
    }

    if (startTime) {
      params += "chargeStartTime=" + startTime + "00&";
    }

    if (endTime) {
      params += "chargeEndTime=" + endTime + "00&";
    }

    if (statName) {
      params += "statName=" + statName + "&";
    }

    if (ChargerPriceFrom) {
      params += "ChargerPriceFrom=" + ChargerPriceFrom + "&";
    }

    if (ChargerPriceTo) {
      params += "ChargerPriceTo=" + ChargerPriceTo + "&";
    }

    if (chargerQuantityFrom) {
      params += "chargerQuantityFrom=" + chargerQuantityFrom + "&";
    }

    if (chargerQuantityTo) {
      params += "chargerQuantityTo=" + chargerQuantityTo + "&";
    }

    url += params;
    
    fetch(url)
      .then((res) => res.json())
      .then((snapshot) => {
        var i = 1;
        // console.log(snapshot);
        var list = [];
        var csvlist = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach((doc) => {
            doc.no = i++;
            list.push(doc);

            csvlist.push({
              '충전소ID': doc.statId ,
              '충전소명': doc.statName,
              '충전기No': '=""' + doc.chargerId + '""',
              '이용건수': doc.cnt ,
              '전력량집계[Wh]': numeral(doc.sum_wh).format('0,0'),
              '충전금액집계[원]': numeral(doc.sum_amount).format('0,0'),
              '회원[Wh]': numeral(doc.sum_wh3).format('0,0'),
              '로밍회원[Wh]': numeral(doc.sum_wh5).format('0,0'),
              '환경부[Wh]': numeral(doc.sum_wh4).format('0,0'),
              '신용[Wh]': numeral(doc.sum_wh1).format('0,0'),
              '회원[원]': numeral(doc.sum_amount3).format('0,0'),
              '로밍회원[원]': numeral(doc.sum_amount5).format('0,0'),
              '환경부[원]': numeral(doc.sum_amount4).format('0,0'),
              '신용[원]': numeral(doc.sum_amount1).format('0,0'),
            });
          });
        }
        setListData(list);
        setCsvData(csvlist);

        if (list.length === 0){
          setDialogMessage('검색 결과가 없습니다.');
          setOpenDialog(true);
        }

        setPage(0);
      });
  };


  const handleSearch = (event) => {

    if (chargeStartTime || chargeEndTime || queryStatName ) {
      searchData(memberName, chargeStartTime, chargeEndTime, queryStatName,
        queryChargerPriceFrom, queryChargerPriceTo,
        queryChargerQuantityFrom, queryChargerQuantityTo);
    } else {
      setDialogMessage('검색어를 입력해 주세요.');
      setOpenDialog(true);
    }
  };


  /**
   * 검색 정보 지우기
   * @param {*} event
   */
  const handleClear = (event) => {
    setMemberName(userAuth !== USER_AUTH.ADMIN ? user.admin_name : '');
    setChargeStartTime(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
    setChargeEndTime(new Date((new Date()).valueOf()));
    setQueryStatName("");
    // setQueryChargerNo("");
    setQueryChargerPriceFrom('');
    setQueryChargerPriceTo('');
    setQueryChargerQuantityFrom('');
    setQueryChargerQuantityTo('');
    setListData ([]);
    setCsvData([]);
  };


  const handleQueryMemberNameChange = (event) => {
    event.persist();
    setMemberName(event.target.value);
  }

  const handleChargeStartTimeChange = (date) => {
    setChargeStartTime(date);
  };

  const handleChargeEndtimeChange = (date) => {
    setChargeEndTime(date);
  };


  const handleQueryNameChange = (event) => {
    event.persist();
    setQueryStatName(event.target.value);
  };


  const handleQueryChargerPriceFrom = (event) => {
    event.persist();
    setQueryChargerPriceFrom(event.target.value);
  }

  const handleQueryChargerPriceTo = (event) => {
    event.persist();
    setQueryChargerPriceTo(event.target.value);
  }

  const handleQueryChargerQuantityFrom = (event) => {
    event.persist();
    setQueryChargerQuantityFrom(event.target.value);
  }

  const handleQueryChargerQuantitiyTo = (event) => {
    event.persist();
    setQueryChargerQuantityTo(event.target.value);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page * rowsPerPage);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }


  //NOTE: 집계 내용이므로 자동 검색 하지 않음.
  useEffect(() => {
    handleSearch();
    return () => { };
  }, []);


  return (

    <div>

      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>주의</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>

      <Card className={clsx(classes.root, className)} {...rest}>
        <Box m={1} p={2}>
          <Box mt={2} display="flex" alignItems="center" justifyContent="start" flexGrow={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <TextField className={classes.queryField_small}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                    </InputAdornment>
                  )
                }}

                disabled={userAuth !== USER_AUTH.ADMIN}
                onChange={handleQueryMemberNameChange}
                label="운영회원사"
                placeholder="운영회원사"
                value={memberName}
                variant="outlined"
                onKeyPress={(ev) => doKeyPress(ev)}
              />


              <KeyboardDateTimePicker
                autoOk
                // maxDate={moment().subtract(0, "days")}
                variant="inline"
                id="date-picker-dialog"
                label="충전시작일시"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                value={chargeStartTime}
                onChange={handleChargeStartTimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 200,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />
              <Typography variant="h5">~</Typography>
              <KeyboardDateTimePicker
                autoOk
                // maxDate={moment().subtract(0, "days")}
                variant="inline"
                id="date-picker-dialog"
                label="충전종료일시"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                value={chargeEndTime}
                onChange={handleChargeEndtimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 200,
                  marginLeft: 20,
                  marginRight: 60,
                }}
              />
            </MuiPickersUtilsProvider>


            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryNameChange}
              label="충전소 명칭"
              placeholder="충전소 명칭"
              value={queryStatName}
              variant="outlined"
              onKeyPress={(ev) => doKeyPress(ev)}
            />
          </Box>


          <Box mt={3} display="flex" alignItems="center" justifyContent="start" flexGrow={1}>
            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChargerPriceFrom}
              label="충전금액[원]"
              placeholder="충전금액[원]"
              value={queryChargerPriceFrom}
              variant="outlined"
              onKeyPress={(ev) => doKeyPress(ev)}
            />
            <Typography variant="h5">~</Typography>
            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChargerPriceTo}
              label="충전금액[원]"
              placeholder="충전금액[원]"
              value={queryChargerPriceTo}
              variant="outlined"
              style={{ marginLeft: 20 }}
              onKeyPress={(ev) => doKeyPress(ev)}
            />

            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChargerQuantityFrom}
              label="충전전력량[Wh]"
              placeholder="충전전력량[Wh]"
              value={queryChargerQuantityFrom}
              variant="outlined"
              style={{
                marginLeft: 20
              }}
              onKeyPress={(ev) => doKeyPress(ev)}
            />
            <Typography variant="h5">~</Typography>
            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChargerQuantitiyTo}
              label="충전전력량[Wh]"
              placeholder="충전전력량[Wh]"
              value={queryChargerQuantityTo}
              variant="outlined"
              style={{
                marginLeft: 20,
                marginRight: 40 }}
              onKeyPress={(ev) => doKeyPress(ev)}
            />


            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.bulkAction}
              onClick={handleSearch}
            >
              검색
            </Button>

            <Button
              variant="outlined"
              size="large"
              className={classes.bulkAction}
              onClick={handleClear}
            >
              초기화
            </Button>
          </Box>

          <Box mt={2} display="flex" alignItems="center" justifyContent="start" flexGrow={1} mb={0}>
            <Typography variant="caption">※△회원 및 환경부카드 결제 및 △신용카드 실결제 △신용카드 결제취소없는 선결제</Typography>
          </Box>

        </Box>


        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <TableContainer style={{ width: '100%', }}>
                <Table >

                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={listData.length}
                  />


                  <TableBody>
                    { stableSort(listData, getComparator(order, orderBy)).slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map( (module, idx) => (
                      <TableRow className={classes.tableRow} >
                        <TableCell align="center" width={COLUMN_WIDTH.STAT_ID}>{module.statId}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.STAT_NAME}>{module.statName}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.CHARGER_NO}>{module.chargerId}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.TRANS_AMOUNT}>{module.cnt}</TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.POWER_AMOUNT}>{numeral(module.sum_wh).format('0,0')}</TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.CHARGING_AMOUNT}>{numeral(module.sum_amount).format('0,0')}</TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.MEMBER_KWH}><Typography variant="body2">{numeral(module.sum_wh3).format('0,0')}</Typography></TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.ROAMINGMEMBER_KWH}><Typography variant="body2">{numeral(module.sum_wh5).format('0,0')}</Typography></TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.LOAMING_KWH}><Typography variant="body2">{numeral(module.sum_wh4).format('0,0')}</Typography></TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.CARD_KWH}><Typography variant="body2">{numeral(module.sum_wh1).format('0,0')}</Typography></TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.USER_CHARGING}><Typography variant="body2">{numeral(module.sum_amount3).format('0,0')}</Typography></TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.ROAMINGUSER_CHARGING}><Typography variant="body2">{numeral(module.sum_amount5).format('0,0')}</Typography></TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.LOAMING_CHARGING}><Typography variant="body2">{numeral(module.sum_amount4).format('0,0')}</Typography></TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.ICCARD_CHARGING}><Typography variant="body2">{numeral(module.sum_amount1).format('0,0')}</Typography></TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </PerfectScrollbar>
        </CardContent>


        <CardActions >

          <Grid container item className={classes.action}
            direction="row" alignItems="center" >

            <Grid item md={6} justify="flex-start">

              <CSVLink
                variant="contained"
                size="large"
                data={csvData}
                filename={`${new Date().toLocaleDateString()}_충전기별매출현황.csv`}
                className="btn btn-primary"
                target="_blank"
                disabled={csvData.length === 0}
                data-interception='off'
              >

                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.bulkAction}
                  disabled={csvData.length === 0}
                >
                  CSV 다운로드
                </Button>
              </CSVLink>
            </Grid>

            <Grid item md={6} justify="flex-end">
              <TablePagination
                component="div"
                count={listData.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Grid>
          </Grid>

        </CardActions>

      </Card>

    </div>
  );
};


SalesChargerTable.propTypes = {
  className: PropTypes.string,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};


export default connect(mapStateToProps)(withRouter(SalesChargerTable));






const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    // width: 300
    flexBasis: 300,
    marginRight: 20,
  },
  queryField_small: {
    flexBasis: 160,
    marginRight: 20,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  datepicker: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
}));